<template>

  <div class="container-fluid" style="
height: 100%;
background-color: #fff;">

    <div class="row justify-content-center pb-5">
      <div style="align-self: center;width: 100%;max-width: 53rem;padding: 6.4rem 2.4rem;">

        <div class="title-wrapper"><h1 class="orb-typography h1">Home Dashboard</h1></div>




      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'HomeComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
