<template>
  <div>
    <div v-for="(social, index) in socials" :key="index">
      <div class="mb-5">
        <div class="mb-3">
          <label :for="`link_${index}`" class="form-label">Inserisci l'URL del profilo</label>
          <input :id="`link_${index}`" :name="`link_${index}`" placeholder="https://www.linkedin.com/in/username" class="form-control" tabindex="0" v-model="social.link"/>
        </div>
        <div class="mb-3">
          <label :for="`type_${index}`" class="form-label">Tipo di social</label>
          <select :id="`type_${index}`" :name="`type_${index}`" class="form-control" v-model="social.type">
            <option value="linkedin">LinkedIn</option>
            <option value="twitter">Twitter</option>
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="tiktok">TikTok</option>
            <option value="whatsapp">WhatsApp</option>
            <option value="telegram">Telegram</option>
          </select>
        </div>
        <div v-if="socials.length < 5 && index === socials.length - 1">
          <button type="button" class="btn btn-primary" @click="addSocial">Aggiungi un nuovo social</button>
        </div>
        <div v-if="socials.length > 1">
          <button type="button" class="btn btn-danger" @click="removeSocial(index)">Rimuovi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
  data() {
    return {
      socials: [
        {type: 'linkedin', link: ''},
      ]
    }
  },
  methods: {
    addSocial() {
      if (this.socials.length < 5) {
        this.socials.push({type: 'linkedin', link: ''})
      }
    },
    removeSocial(index) {
      this.socials.splice(index, 1);
    }
  }
}
</script>