<template>
  <div>
    <div v-for="(address, index) in addresses" :key="index">
      <div class="mb-5 row">
        <div class="mb-3 col-md-6" >
          <div class="form-floating">
          <input :id="`street_${index}`" :name="`street_${index}`" placeholder="Via, numero civico" class="form-control" tabindex="0" v-model="address.street"/>
            <label :for="`street_${index}`">Indirizzo</label>
        </div>
        </div>
        <div class="mb-3 col-md-6">
          <div class="form-floating">
            <input :id="`city_${index}`" :name="`city_${index}`" placeholder="Città" class="form-control" tabindex="0" v-model="address.city"/>
            <label :for="`city_${index}`">Città</label>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input :id="`region_${index}`" :name="`region_${index}`" placeholder="Regione" class="form-control" tabindex="0" v-model="address.region"/>
            <label :for="`region_${index}`">Regione</label>
          </div>
        </div>
        <div class="mb-3">
            <div class="form-floating">
              <input :id="`postalCode_${index}`" :name="`postalCode_${index}`" placeholder="Codice postale" class="form-control" tabindex="0" v-model="address.postalCode"/>
              <label :for="`postalCode_${index}`">Codice postale</label>
            </div>
        </div>

        <div class="mb-3">
            <div class="form-floating">
              <input :id="`country_${index}`" :name="`country_${index}`" placeholder="Paese" class="form-control" tabindex="0" v-model="address.country"/>
              <label :for="`country_${index}`">Paese</label>
            </div>
        </div>

        <div class="mb-3">
          <label :for="`type_${index}`" class="form-label">Tipo di indirizzo</label>
          <select :id="`type_${index}`" :name="`type_${index}`" class="form-control" v-model="address.type">
            <option value="WORK">Work</option>
            <option value="HOME">Home</option>
          </select>
        </div>
        <div v-if="addresses.length < 2 && index === addresses.length - 1">
          <button type="button" class="btn btn-primary" @click="addAddress">Aggiungi un altro indirizzo</button>
        </div>
        <div v-if="addresses.length > 1">
          <button type="button" class="btn btn-danger" @click="removeAddress(index)">Rimuovi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vCardAddress',
  data() {
    return {
      addresses: [
        {type: 'WORK', street: '', city: '', region: '', postalCode: '', country: ''},
      ]
    }
  },
  methods: {
    addAddress() {
      if (this.addresses.length < 2) {
        this.addresses.push({type: 'WORK', street: '', city: '', region: '', postalCode: '', country: ''})
      }
    },
    removeAddress(index) {
      this.addresses.splice(index, 1);
    }
  }
}
</script>