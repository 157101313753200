<template>
  <form @submit.prevent="createQRCode">
    <div class="content-header sticky-header">
      <h1 class="section--header min-w-section--header">Crea un Qr Code</h1>


      <fieldset class="segmented-control">
      <div class="segments">
        <label class="segment slide" :class="{ active: qrcodeType === 'qrcode' }" for="qrcode">
          Qr Code
          <button type="button" id="qrcode" @click="qrcodeType = 'qrcode'">Qr Code</button>
        </label>
        <label class="segment slide" :class="{ active: qrcodeType === 'vcard' }" for="vcard">
          vCard
          <button type="button" id="vcard" @click="qrcodeType = 'vcard'">vCard</button>
        </label>
      </div>
      </fieldset>


      <div></div>
    </div>

    <div class="container-fluid ps-0 qrc-page-container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 p-0">
          <div class="wrap-dati-outer">
            <div class="wrap-dati-container" v-on:scroll="onScroll">
            <!-- Utilizziamo la direttiva v-if per caricare dinamicamente il componente selezionato -->
            <div v-if="qrcodeType === 'qrcode'">
              <qrcode-component></qrcode-component>
            </div>
            <div v-if="qrcodeType === 'vcard'">
              <vcard-component></vcard-component>
            </div>
          </div>
          </div>
        </div>
        <div class="col-12 col-md-5 sticky-top p-0">
          <div class="preview-container">
            <h4 class="orb-typography h4">Preview</h4>
            <div id="qr-code-container" class="qr-preview-image"></div>
          </div>
        </div>
      </div>
    </div>

  </form>
</template>

<script>
import qrcodeComponent from './CreateQrCode.vue';
import VcardComponent from './CreateVcard.vue';
import mitt from 'mitt'; // importa la libreria mitt

const emitter = mitt(); // crea un istanza di mitt per gestire gli eventi

export default {
  name: 'NewQrcode',
  components: {
    qrcodeComponent,
    VcardComponent
  },
  data() {
    return {
      qrcode_link: '',
      title: '',
      useqrcode: false,
      qrcode_link_type: 'direct',
      message: '',
      qrcodeType: 'qrcode'
    };
  },
  mounted() {
    const header = document.querySelector('.content-header');

    const onScroll = () => {
      if (header.getBoundingClientRect().top < 0) {
        header.classList.add('sticky-up');
      } else {
        header.classList.remove('sticky-up');
      }
    };

    window.addEventListener('scroll', onScroll);
    emitter.on('beforeUnmount', () => { // sostituisce this.$once('hook:beforeUnmount', ...) con emitter.on('beforeUnmount', ...)
      window.removeEventListener('scroll', onScroll);
    });

  },
  methods: {
    createQRCode() {
      if (this.qrcodeType === 'qrcode') {
        this.$refs.qrcodeComponent.createQRCode();
      } else if (this.qrcodeType === 'vcard') {
        this.$refs.VcardComponent.createVcard();
      }
    },
    switchSlide() {
      const segments = document.querySelectorAll('.segment');
      segments.forEach(segment => {
        if (segment.classList.contains('active')) {
          segment.classList.remove('active');
        }
      });
    }
  },
};
</script>


<style>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 997; /* valore superiore rispetto ad altri eventuali elementi che potrebbero coprire l'header durante lo scroll */
}
.wrap-dati-outer {
  align-self: center;
  width: 100%;
  margin: auto;
  overflow-y: auto;
  height: calc(100vh - 10rem);
}
.wrap-dati-container {
  align-self: center;
  width: 100%;
  max-width: 45rem;
  padding: 6.4rem 2.4rem 0 2.4rem;
  margin: auto;
}



.slide {
  transition: all 0.3s ease-in-out;
}


.segmented-control .segments {
  display: flex;
  overflow: hidden;
  position: relative;
}

.segmented-control .segment {
  flex: 1;
  text-align: center;
}

.segmented-control .segment button {
  position: absolute;
  left: -9999px;
}

.segmented-control .segment.active {
  background-color: #000;
  color: #fff;
}

.segmented-control .segments:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: var(--segment-width, 0);
  height: 100%;
  background-color: #999;
  transition: width 0.3s ease-in-out;
}

.segmented-control .segments {
  display: inline-flex;
  justify-content: stretch;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #f4f6fa;
  font-size: 1rem;
  line-height: 1rem;
  height: 2.6rem;
  width: 100%;
}
.qrc-page-container .create-qrc-header .fullscreen-container .segmented-control .segments .segment {
  width: 16rem;
}
.segmented-control.disabled .segments .segment.active {
  background-color: #dbe0eb;
  color: #273144;
}
.segmented-control.disabled .segments .segment {
  color: #c3cbdc;
}
.segmented-control .segments .segment.active {
  background-color: #fff;
  color: #273144;
  border-radius: 5px;
  box-shadow: 0 0.1rem 0.4rem rgba(21,27,38,.12);
}
.segmented-control .segments .segment {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  color: #91A1C0;
  padding: 0.5rem 1rem;
  width: 9em;
  margin: 0.4rem;
}
.segmented-control.disabled .segments .segment button {
  cursor: not-allowed;
}
.segmented-control .segments .segment button {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.qrc-page-container .create-qrc-header .fullscreen-container .segmented-control .segments .segment {
  width: 16rem;
}

.segmented-control.disabled .segments .segment {
  color: #c3cbdc;
}
</style>