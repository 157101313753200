
import axios from 'axios';
const API_LOGIN_ENDPOINT = 'https://api.principiadv.online/wp-json/api-rest-auth-vue/v1/login';


export function endpointUrl(endpoint) {
    return 'https://api.principiadv.online/wp-json/shortlink/v1/' + endpoint;
}

export async function login({ username, password }) {
    try {
        const response = await axios.post(API_LOGIN_ENDPOINT, { username, password });
        const { access_token, user } = response.data;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('user_data', JSON.stringify(user));

        const savedToken = localStorage.getItem('access_token');
        const userData = readUserData();

        if (savedToken === access_token && userData === user) {
            //console.log('Il token e i dati utente sono stati correttamente salvati nella localStorage:', savedToken, userData);
            return true;
        } else {
            console.error('Errore nel salvataggio del token e/o dei dati utente nella localStorage!');
        }


    } catch(error) {
        console.error(error);
        return false;
    }
}

export function saveUserData(userData) {
    localStorage.setItem('user_data', JSON.stringify(userData));
    //console.log('Dati utente salvati nella localStorage:', userData);
}

function readUserData() {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    //console.log("I dati utente letti dalla localStorage sono:", userData);
    return userData;
}
export function clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_data');
}

export function getAccessToken() {
    return localStorage.getItem('access_token') || '';

}

export function getUserData() {
    return JSON.parse(localStorage.getItem('user_data') || '{}');
}

export async function checkBearerTokenValidity() {
    const token = localStorage.getItem('access_token');
    try {
        const response = await axios.get('https://api.principiadv.online/wp-json/api-rest-auth-vue/v1/get-bearer-token', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.success;
    } catch (error) {
        console.error(error);
        return false;
    }
}
export async function checkTokenValidityAndRenew() {
    const is_valid = await checkBearerTokenValidity();
    if (!is_valid) {
        const response = await renewToken();
        if (response) {
            return true;
        } else {
            clearToken();
            return false;
        }
    }
    return true;
}

export async function renewToken() {
    const token = localStorage.getItem('access_token');
    try {
        const response = await axios.post('https://api.principiadv.online/wp-json/api-rest-auth-vue/v1/renew_token', {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const new_token = response.data.new_token;
        localStorage.setItem('access_token', new_token);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}
export function saveToken(token) {
    localStorage.setItem('access_token', token);
    //console.log('Token salvato nella localStorage:', token);
}
function redirect(route) {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    const redirectUrl = params.get('redirectUrl');
    if (redirectUrl) {
        window.location.href = new URL(redirectUrl, window.location.origin);
    } else {
        window.location.href = new URL(route, window.location.origin);
    }
}
export function redirectToPage(url) {
    window.location.replace(url);
}
export function removeToken() {
    localStorage.removeItem('access_token');
}
export default {
    login,
    clearToken,
    endpointUrl,
    getAccessToken,
    saveToken,
    removeToken,
    redirect,
    redirectToPage,
    getUserData,
};



