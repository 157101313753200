<template>
  <div class="container-fluid" style="height: 100%; background-color: #fff;">
    <div class="row justify-content-center pb-5">
      <div style="align-self: center;width: 100%;max-width: 53rem;padding: 6.4rem 2.4rem;">
        <div class="title-wrapper"><h1 class="orb-typography h1">Create new</h1></div>
        <div class="mb-5">
          <label for="qrcode_link" class="form-label">Destinazione</label>
          <input
              id="qrcode_link"
              placeholder="https://example.com/my-long-url"
              class="form-control"
              autocomplete="off"
              maxlength="6144"
              tabindex="0"
              v-model="qrcode_link"
          />
        </div>
        <div class="mb-5">
          <label for="title" class="form-label">Titolo <small>(Facoltativo)</small></label>
          <input
              id="title"
              class="form-control"
              autocomplete="off"
              maxlength="2048"
              tabindex="0"
              v-model="title"
          />
        </div>
        <div class="form-check form-switch custom-control-lg mb-5">
          <input
              class="form-check-input"
              type="checkbox"
              id="utm_check"
              name="utm_check"
              v-model="showUtmPanel"
          />
          <label class="form-check-label" for="utm_check">Aggiungi UTM per tenere traccia del traffico web negli strumenti di analisi</label>
        </div>
        <div class="panel-utm" v-show="showUtmPanel">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <label for="qrcode_utm_fonte" class="form-label">Fonte</label>
                <input placeholder="es., google, newsletter" class="form-control" autocomplete="off" tabindex="0" data-test="input-field" v-model="qrcode_utm_fonte">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <label for="qrcode_utm_mezzo" class="form-label">Mezzo</label>
                <input placeholder="es. cpc, banner, email" class="form-control" autocomplete="off" tabindex="0" data-test="input-field" v-model="qrcode_utm_mezzo">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <label for="qrcode_utm_campagna" class="form-label">Campagna</label>
                <input placeholder="ad esempio, saldi_primaverili" class="form-control" autocomplete="off" tabindex="0" data-test="input-field" v-model="qrcode_utm_campagna">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <label for="qrcode_utm_termine" class="form-label">Termine <small>(Facoltativo)</small></label>
                <input placeholder="ad esempio, qualcosa" class="form-control" autocomplete="off" tabindex="0" data-test="input-field" v-model="qrcode_utm_termine">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <label for="qrcode_utm_contenuto" class="form-label">Contenuto <small>(Facoltativo)</small></label>
                <input placeholder="ad esempio, qualcos'altro" class="form-control" autocomplete="off" tabindex="0" data-test="input-field" v-model="qrcode_utm_contenuto">
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-5">
              <h5>UTM tag preview</h5>
              <div class="utm-preview">{{ utmPreviewResult }}</div>
            </div>
          </div>
        </div>
        <h3 class="orb-typography h3 pb-3">
          Codice QR <small>(facoltativo)</small>
        </h3>
        <div class="form-check form-switch custom-control-lg">
          <input
              class="form-check-input"
              type="checkbox"
              id="crea_qrcode"
              name="crea_qrcode"
              v-model="crea_qrcode"
          />
          <label class="form-check-label" for="crea_qrcode">Genera un codice QR da condividere ovunque le persone possano scansionarlo</label>
        </div>
        <div v-if="message" class="alert alert-success mt-3">
          {{ message }}
        </div>
        <div class="d-grid gap-2 col-12 mt-5 mx-auto">
          <button class="btn btn-primary btn-lg mt-5" @click="saveLink()">Salva</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewLinks",
  data() {
    return {
      qrcode_link: "",
      title: "",
      showUtmPanel: false,
      qrcode_utm_fonte: "",
      qrcode_utm_mezzo: "",
      qrcode_utm_campagna: "",
      qrcode_utm_termine: "",
      qrcode_utm_contenuto: "",
      crea_qrcode: false,
      useShortlink: true,
      message: "",
      utmPreviewResult: ""
    };
  },
  methods: {
    async saveLink() {
      try {
        const response = await fetch(
            "https://api.principiadv.online/wp-json/shortlink/v1/shortlink/add_shortlink",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                url: this.qrcode_link,
                title: this.title,
                utm_check: this.showUtmPanel ? "si_utm" : undefined,
                utm_fonte: this.qrcode_utm_fonte.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_'),
                utm_mezzo: this.qrcode_utm_mezzo.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_'),
                utm_campagna: this.qrcode_utm_campagna.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_'),
                utm_termine: this.qrcode_utm_termine.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_'),
                utm_contenuto: this.qrcode_utm_contenuto.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_'),
                shortlink: this.useShortlink,
                qrcode: this.crea_qrcode ? "create" : undefined,
                utm_preview: this.utmPreviewResult
              }),
            }
        );
        const json = await response.json();
        this.message = "Link creato con successo!" + json;
      } catch (error) {
        console.error(error);
        this.message = "Si è verificato un errore durante la creazione del link";
      }
    },

    generateUTMPreview() {
      let preview = "?";
      if (this.qrcode_utm_fonte) {
        preview += "utm_source=" + encodeURIComponent(this.qrcode_utm_fonte.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_')) + "&";
      }
      if (this.qrcode_utm_mezzo) {
        preview += "utm_medium=" + encodeURIComponent(this.qrcode_utm_mezzo.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_')) + "&";
      }
      if (this.qrcode_utm_campagna) {
        preview += "utm_campaign=" + encodeURIComponent(this.qrcode_utm_campagna.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_')) + "&";
      }
      if (this.qrcode_utm_termine) {
        preview += "utm_term=" + encodeURIComponent(this.qrcode_utm_termine.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_')) + "&";
      }
      if (this.qrcode_utm_contenuto) {
        preview += "utm_content=" + encodeURIComponent(this.qrcode_utm_contenuto.normalize("NFD").replaceAll(/[\u0300-\u036f'"]/g, "").replaceAll(/\s+/g, '_')) + "&";
      }
      return preview.slice(0, -1);
    },

    updateUTMPreview() {
      this.utmPreviewResult = this.generateUTMPreview();
    },
  },
  watch: {
    qrcode_utm_fonte: "updateUTMPreview",
    qrcode_utm_mezzo: "updateUTMPreview",
    qrcode_utm_campagna: "updateUTMPreview",
    qrcode_utm_termine: "updateUTMPreview",
    qrcode_utm_contenuto: "updateUTMPreview",
    utmPreviewResult: function(value) {
      if (this.showUtmPanel) {
        this.$el.querySelector(".utm-preview").innerHTML = value;
      }
    }
  },
};
</script>

<style>
.utm-preview {
  font-size: .9rem;
  line-height: 2rem;
  margin: 0 0 0.8rem;
  padding-bottom: 1.6rem;
  word-break: break-all;
  color: #273144;
}
</style>