<template>
  <div class="shortlink-wrapper" style="max-height: 100vh; overflow-y: auto; padding: 2em 1em 2em 0;">
    <div class="shortlink-header">
      <div class="header-content">
        <div class="header-content-top">
          <h2 class="orb-typography h2 no-margin header-title">{{shortlinkDetails.title}}</h2>
          <div class="header-top-buttons">
            <a href="#" class="btn btn-light" tabindex="0">
              <span class="orb-button-icon">
                <span class="orb-icon">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><title>Edit</title><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                </span>
              </span>
              Edit
            </a>
          </div>
        </div>
        <div class="header-info created">
          <span class="created-info">
            <p class="orb-typography p no-margin">{{shortlinkDetails.data}} by {{shortlinkDetails.autore}}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="shortlink-body">
      <div class="body-content-top">
        <h2 class="orb-typography h2 no-margin">
          <a class="body-title" :href="shortlinkDetails.url" target="_blank">{{shortlinkDetails.title}}</a>
        </h2>
        <div class="body-top-buttons">
          <a
              href="#"
              class="btn btn-light"
              tabindex="0"
              ref="copyButton"
              v-b-popover.hover.top="'Link copied successfully!'"
              :visible="popoverVisible"
              @click="copyLink"
          >
      <span class="orb-button-icon">
        <span class="orb-icon">
          <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
          >
            <title>copy</title>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
          </svg>
        </span>
      </span>
            Copy
          </a>
        </div>
      </div>
      <div class="body-longurl">
        <span class="orb-icon" title="redirect">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><title>redirect</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"></path></svg>
        </span>
        <span class="link-destination-url">
          <a :href="shortlinkDetails.url" target="_blank" rel="noopener noreferrer" class="redirect-anchor">{{shortlinkDetails.qrcode_shortlink}}</a>
        </span>
        <div class="disable-redirect" >
          <div tabindex="0" class="orb-tooltip top-center disable-redirect-tooltip" >
            <div class="anchor">
              <span>
                <span class="orb-icon redirect-lock">

                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><title>Icon</title><path fill="none" d="M0 0h24v24H0z"></path><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path></svg>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="body-content-mid">
        <div class="mid-section">
          <div class="mid-section-title mb-0">
            <h3 class="orb-typography h3 pb-0 mb-0">
              QR Code
            </h3>
          </div>
          <p class="link-info--help-text pb-1">Genera un codice QR da condividere ovunque le persone possano scansionarlo</p>
          <div class="mid-section-content" >
            <div class="qrc-mock">
              <template v-if="shortlinkDetails.svg_name">
                <img class="qrc-image" :src="`https://test.principiadv.online/wp-content/uploads/qrcode/${shortlinkDetails.svg_name}`" alt="QR Code">
              </template>
              <template v-else>
                <img :src="'https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=' + shortlinkDetails.url" alt="QR Code" />
              </template>
            </div>
            <div class="mid-section-content-right" >
              <div class="mid-section-buttons" >
                <a href="#" class="btn btn-light" tabindex="0" v-b-popover.hover.top="'Genera un codice QR da condividere ovunque le persone possano scansionarlo'">
                  <span class="orb-button-icon">
                    <span class="orb-icon">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" height="20" width="20"><path d="M4.167 9.167H7.5c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167V7.5c0 .917.75 1.667 1.667 1.667zm0-5H7.5V7.5H4.167V4.167zm0 13.333H7.5c.917 0 1.667-.75 1.667-1.667V12.5c0-.917-.75-1.667-1.667-1.667H4.167c-.917 0-1.667.75-1.667 1.667v3.333c0 .917.75 1.667 1.667 1.667zm0-5H7.5v3.333H4.167V12.5zm6.666-5V4.167c0-.917.75-1.667 1.667-1.667h3.333c.917 0 1.667.75 1.667 1.667V7.5c0 .917-.75 1.667-1.667 1.667H12.5c-.917 0-1.667-.75-1.667-1.667zm1.667 0h3.333V4.167H12.5V7.5zm5 9.583v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.184.417.417.417h.833a.413.413 0 00.417-.417zm-6.667-5v-.833c0-.233.184-.417.417-.417h.833c.234 0 .417.184.417.417v.833a.413.413 0 01-.417.417h-.833a.413.413 0 01-.417-.417zm2.917.417h-.833a.413.413 0 00-.417.417v.833c0 .233.183.417.417.417h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417zm-2.917 2.917v-.834c0-.233.184-.416.417-.416h.833c.234 0 .417.183.417.416v.834a.413.413 0 01-.417.416h-.833a.413.413 0 01-.417-.416zm2.084 2.083h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.183.417.417.417zm2.5-1.667h-.834a.413.413 0 01-.416-.416v-.834c0-.233.183-.416.416-.416h.834c.233 0 .416.183.416.416v.834a.413.413 0 01-.416.416zm0-5h-.834a.413.413 0 00-.416.417v.833c0 .234.183.417.416.417h.834a.413.413 0 00.416-.417v-.833a.413.413 0 00-.416-.417zm1.666 3.334h-.833a.413.413 0 01-.417-.417v-.833c0-.234.184-.417.417-.417h.833c.234 0 .417.183.417.417v.833a.413.413 0 01-.417.417z" clip-rule="evenodd" fill="#273144"></path></svg>
                    </span>
                  </span>
                  Genera QR Code
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinksDetails',
  props: {
    shortlinkDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      popoverVisible: false
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard
          .writeText(this.shortlinkDetails.qrcode_link)
          .then(() => {
            console.log('Copied successfully!');
            this.popoverVisible = true;
            setTimeout(() => {
              this.popoverVisible = false;
            }, 1000);
          })
          .catch(error => {
            console.error('Copy failed:', error);
          });
    }
  }
};
</script>

<style>
.qrc-image {
  width: 6em;
  height: 6em;
}
</style>