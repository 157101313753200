<template>
  <div id="app">
    <login-app v-if="!loggedIn" @loggedin="loggedIn = true">
      <router-view></router-view>
    </login-app>

    <user-app-layout v-else>
      <router-view></router-view>
    </user-app-layout>
  </div>
</template>

<script>
import LoginApp from './components/LoginApp.vue'
import UserAppLayout from './components/UserAppLayout.vue'
import {computed, onMounted} from 'vue';
import {checkTokenValidityAndRenew, redirectToPage} from './api';
//import router from './router';

export default {
  name: 'App',
  components: {
    LoginApp,
    UserAppLayout,
  },
  setup() {
    const loggedIn = computed(() => {
      const access_token = localStorage.getItem('access_token');
      return access_token !== null && access_token !== undefined;
    });


    onMounted(() => {
      setInterval(() => {
        const access_token = localStorage.getItem('access_token');
        if (access_token === null || access_token === undefined) {
          localStorage.clear();
          //router.push('/login');
          redirectToPage('./login');
        } else {
          checkTokenValidityAndRenew().then(valid_token => {
            if (!valid_token) {
              localStorage.clear();
             // router.push('/login');
              redirectToPage('./login');
            }
          });
        }
      }, 60000); // verifica del token ogni minuto
    });

    return {loggedIn};
  },
};
</script>