<template>
<div class="sidebar">
  <div class="sidebar-header" id="sidebar-header">
    <router-link to="/" class="sidebar-logo"><span></span></router-link>
    <router-link to="/" class="sidebar-logo-text">Short<span>Links</span></router-link>
  </div><!-- sidebar-header -->


  <div class="sidebar-body" id="sidebar-body">
    <nav class="nav-sidebar">
      <li class="nav-item mb-4">
        <a href="" class="nav-link with-sub btn btn-primary add-new"><i data-feather="plus"></i><span>Crea nuovo</span></a>
        <nav class="nav-sub">
          <router-link to="/new-links" class="sub-link">
            <svg viewBox="0 0 20 20" height="20" width="20"><path fill="#273144" d="M4.917 8.833c-.5-.583-.75-1.25-.75-1.916 0-.75.25-1.417.833-1.917 1-1 2.833-1 3
                          .833 0l1.834 1.833a.805.805 0 001.166 0 .805.805 0 000-1.166L10 3.75C9.167 3 8 2.5 6.833 2.5c-1.166 0-2.25.5-3.083 1.25-.833.833-1.25 2-1.25 3.167 0 1.166.417 2.25 1.25 3.083l1.833 1.833a.9.9 0 00.584.25.9.9 0 00.583-.25.805.805 0 000-1.166L4.917 8.833zM16.25 10l-1.833-1.833a.806.806 0 00-1.167 0 .806.806 0 000 1.166l1.833 1.917c.5.5.834 1.167.834 1.917s-.25 1.416-.834 1.916c-1.083 1.084-2.75 1.084-3.833 0L9.417 13.25a.806.806 0 00-1.167 0 .806.806 0 000 1.167l1.833 1.833c.834.833 2 1.25 3.084 1.25 1.083 0 2.25-.417 3.083-1.25.833-.833 1.25-1.917 1.25-3.083 0-1.25-.417-2.334-1.25-3.167z"></path><path fill="#273144" d="M12.25 12.25a.756.756 0 01-.583.25.757.757 0 01-.584-.25L7.75 8.917a.806.806 0 010-1.167.806.806 0 011.167 0l3.333 3.333a.806.806 0 010 1.167z"></path></svg>
            <span>Link</span>
          </router-link>
          <router-link to="/new-qrcode" class="sub-link">
            <svg viewBox="0 0 20 20" height="20" width="20"><path d="M4.167 9.167H7.5c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167V7.5c0 .917.75 1.667 1.667 1.667zm0-5H7.5V7.5H4.167V4.167zm0 13.333H7.5c.917 0 1.667-.75 1.667-1.667V12.5c0-.917-.75-1.667-1.667-1.667H4.167c-.917 0-1.667.75-1.667 1.667v3.333c0 .917.75 1.667 1.667 1.667zm0-5H7.5v3.333H4.167V12.5zm6.666-5V4.167c0-.917.75-1.667 1.667-1.667h3.333c.917 0 1.667.75 1.667 1.667V7.5c0 .917-.75 1.667-1.667 1.667H12.5c-.917 0-1.667-.75-1.667-1.667zm1.667 0h3.333V4.167H12.5V7.5zm5 9.583v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.184.417.417.417h.833a.413.413 0 00.417-.417zm-6.667-5v-.833c0-.233.184-.417.417-.417h.833c.234 0 .417.184.417.417v.833a.413.413 0 01-.417.417h-.833a.413.413 0 01-.417-.417zm2.917.417h-.833a.413.413 0 00-.417.417v.833c0 .233.183.417.417.417h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417zm-2.917 2.917v-.834c0-.233.184-.416.417-.416h.833c.234 0 .417.183.417.416v.834a.413.413 0 01-.417.416h-.833a.413.413 0 01-.417-.416zm2.084 2.083h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.183.417.417.417zm2.5-1.667h-.834a.413.413 0 01-.416-.416v-.834c0-.233.183-.416.416-.416h.834c.233 0 .416.183.416.416v.834a.413.413 0 01-.416.416zm0-5h-.834a.413.413 0 00-.416.417v.833c0 .234.183.417.416.417h.834a.413.413 0 00.416-.417v-.833a.413.413 0 00-.416-.417zm1.666 3.334h-.833a.413.413 0 01-.417-.417v-.833c0-.234.184-.417.417-.417h.833c.234 0 .417.183.417.417v.833a.413.413 0 01-.417.417z" clip-rule="evenodd" fill="#273144"></path></svg>
            <span>QR Code</span>
          </router-link>
        </nav>
      </li>
      <!--<router-link to="/" class="nav-link active"><i data-feather="package"></i><span>Dashboard</span></router-link>-->
      <router-link to="/links" class="nav-link"><i data-feather="monitor"></i><span>Links</span></router-link>
      <router-link to="/qrcode" class="nav-link"><i data-feather="shopping-bag"></i><span>Qr Codes</span></router-link>
      <router-link to="/" class="nav-link"><i data-feather="file-text"></i><span>Gruppi</span></router-link>
    </nav>

    <hr>

    <nav class="nav-sidebar">
      <router-link to="/profilo" class="nav-link"><i data-feather="users"></i><span>Manage Accounts</span></router-link>
      <router-link to="/" class="nav-link"><i data-feather="package"></i><span>Resources</span></router-link>
      <router-link to="/" class="nav-link"><i data-feather="file-text"></i><span>Documents</span></router-link>
    </nav>

  </div><!-- sidebar-body -->
</div><!-- sidebar -->

</template>


<script>
import feather from 'feather-icons';

export default {
  name: 'SidebarMenu',
  mounted() {
    feather.replace();
  }
}
</script>