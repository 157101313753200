<template>
  <sidebar-menu></sidebar-menu>
  <div class="content">
    <ToolBar></ToolBar>
    <router-view></router-view>
  </div>
</template>

<script>

import SidebarMenu from './SidebarMenu.vue';
import ToolBar from './ToolBar.vue';
export default {
  name: 'UserAppLayout',

  components: {
    SidebarMenu,
    ToolBar,
  },
}
</script>