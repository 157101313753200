<template>
  <!-- div principale del template -->
  <div>
    <div class="content">
    <!-- blocco if per l'area di login, viene mostrato solo se non c'è una sessione attiva -->
    <div v-if="!loggedIn">
      <!-- div per la sezione di login -->
      <div class="login">
        <h1>Login</h1>
        <!-- form per login con submit.prevent per evitare il comportamento predefinito del form e gestirlo tramite Vue.js -->
        <form @submit.prevent="submitForm">
          <!-- campo input per username con v-model per legare la variabile di stato -->
          <div class="form-group">
            <label>Username:</label>
            <input type="text" v-model="state.username" />
          </div>
          <!-- campo input per password con v-model per legare la variabile di stato -->
          <div class="form-group">
            <label>Password:</label>
            <input type="password" v-model="state.password" />
          </div>
          <!-- bottone di submit per eseguire la funzione submitForm -->
          <button type="submit">Login</button>
        </form>
        <!-- paragrafo per messaggio di errore in caso di problemi di login -->
        <p v-show="state.error" class="error">{{ state.error }}</p>
      </div>
    </div>
    <!-- blocco else per l'area di profilo, viene mostrato solo se c'è una sessione attiva -->
    <div v-else>
      <router-link to="/profilo">Vai al profilo</router-link>
      <!-- router-link per reindirizzare alla pagina del profilo, definita nelle route -->
    </div>
  </div>
  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import {checkTokenValidityAndRenew, redirectToPage, saveToken, saveUserData} from '../api'; // modifiche qui: import delle funzioni da api.js
//import router from '../router';

const API_LOGIN_URL = 'https://api.principiadv.online/wp-json/api-rest-auth-vue/v1/login';

export default {
  name: 'LoginApp',
  setup() {
    const state = reactive({
      username: '',
      password: '',
      error: '',
    });

    async function submitForm() {
      try {
        const response = await fetch(API_LOGIN_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(state),
        });

        if (!response) {
          throw new Error(response.statusText);
        }

        const loginData = await response.json();

        saveToken(loginData.access_token);
        saveUserData(loginData); // salva anche i dati utente nella localStorage
        await checkTokenValidityAndRenew();

        // Utilizza il metodo $router.push del router di Vue per effettuare la redirect.

        redirectToPage('./profilo')
      } catch (e) {
        state.error = 'Errore durante il login. Riprova.';
      }
    }


    const loggedIn = computed(() => localStorage.getItem('access_token'));


    return {
      state,
      submitForm,
      loggedIn,
    };
  },
};
</script>