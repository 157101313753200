<template>
  <div>
    <div v-for="(email, index) in emails" :key="index">
      <div class="mb-5">
        <div>
          <label :for="`email_${index}`" class="form-label">Email</label>
          <input :id="`email_${index}`" :name="`email_${index}`" placeholder="Indirizzo email" class="form-control" tabindex="0" v-model="email.value"/>
        </div>
        <div>
          <label :for="`type_${index}`" class="form-label">Tipo di email</label>
          <select :id="`type_${index}`" :name="`type_${index}`" class="form-control" v-model="email.type">
            <option value="WORK">Work</option>
            <option value="PERSONAL">Personale</option>
            <option value="HOME">Home</option>
          </select>
        </div>
        <div v-if="emails.length < 3 && index === emails.length - 1">
          <button type="button" class="btn btn-primary" @click="addEmail">Aggiungi un'altra email</button>
        </div>
        <div v-if="emails.length > 1">
          <button type="button" class="btn btn-danger" @click="removeEmail(index)">Rimuovi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vCardEmail',
  data() {
    return {
      emails: [
        {type: 'WORK', value: ''},
      ]
    }
  },
  methods: {
    addEmail() {
      if (this.emails.length < 3) {
        this.emails.push({type: 'WORK', value: ''})
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    }
  }
}
</script>