<template>
  <div>
    <div v-for="(tel, index) in tels" :key="index">
      <div class="mb-5 row">
        <div class="col-md-6">
          <label :for="`tel_${index}`" class="form-label">Telefono</label>
          <input :id="`tel_${index}`" :name="`tel_${index}`" placeholder="Telefono" class="form-control" tabindex="0" v-model="tel.value"/>
        </div>
        <div class="col-md-6">
          <label :for="`type_${index}`" class="form-label">Tipo di numero</label>
          <select :id="`type_${index}`" :name="`type_${index}`" class="form-control" v-model="tel.type">
            <option value="WORK">Work</option>
            <option value="HOME">Home</option>
            <option value="CELL">Cell</option>
          </select>
        </div>
        <div v-if="tels.length < 3 && index === tels.length - 1">
          <button type="button" class="btn btn-primary" @click="addTel">Aggiungi un altro numero</button>
        </div>
        <div v-if="tels.length > 1">
          <button type="button" class="btn btn-danger" @click="removeTel(index)">Rimuovi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vCardTelefono',
  data() {
    return {
      tels: [
        {type: 'WORK', value: ''},
      ]
    }
  },
  methods: {
    addTel() {
      if (this.tels.length < 3) {
        this.tels.push({type: 'WORK', value: ''})
      }
    },
    removeTel(index) {
      this.tels.splice(index, 1);
    }
  }
}
</script>