<template>
  <div>
    <h1 v-if="state.profile">
      Benvenuto {{ state.profile.display_name ? state.profile.display_name : 'utente' }}!
    </h1>
    <div v-if="state.loading">Caricamento...</div>
    <div v-else v-show="loggedIn">
      <p>Il tuo indirizzo email è: {{ state.profile.user_email ? state.profile.user_email : '-' }}</p>
      <button @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue';
import { removeToken } from '../api.js';
import router from '../router';

const API_USER_URL = 'https://api.principiadv.online/wp-json/api-rest-auth-vue/v1/user';

export default {
  name: 'ProfileApp',
  setup() {
    const state = reactive({
      loading: true,
      profile: null,
      error: null,
    });

    const loggedIn = computed(() => localStorage.getItem('access_token'));

    onMounted(async () => {
      try {
        const token = localStorage.getItem('access_token');

        // Check if the user is redirected from the login page
        const previousPath = router.currentRoute.value.meta.from;
        const isFromLoginPage = previousPath === '/login';
        if (isFromLoginPage) {
          // Reload the page
          window.location.reload();
          return;
        }

        const response = await fetch(API_USER_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          state.loading = false;
          state.profile = data;
        } else {
          throw new Error(response.statusText);
        }

      } catch (e) {
        console.error(e);
        state.loading = false;
        state.error = 'Errore durante la richiesta di dati utente.';
      }
    });

    function logout() {
      removeToken();
      router.push('/login');
    }

    return {
      state,
      logout,
      loggedIn, // Aggiungi anche il valore della computata "loggedIn" alla return per renderlo disponibile al di fuori del setup
    };
  },
};
</script>