import { createApp } from "vue";
import axios from "axios";
import VueCookies from 'vue-cookies';

import App from "./App.vue";
import router from "./router";

import "./assets/plugins/remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap";
import "./assets/css/all.min.css";
import "./assets/js/custom.js";

import Popper from "popper.js";

const app = createApp(App);
app.use(router);
app.use(VueCookies);
app.config.globalProperties.$axios = axios;

app.mount("#app");

window.Popper = Popper;