import { createRouter, createWebHistory } from "vue-router";
//import { requireAuth } from './middleware';
import { checkTokenValidityAndRenew } from './api';

import Home from "./components/HomeComponent.vue";
import LoginApp from './components/LoginApp.vue';
import Profilo from './components/ProfiloApp.vue';


import Qrcode from "./components/module/QrcodeComponent.vue";
import Links from "./components/module/LinksComponent.vue";
import NewLinks from "./components/module/NewLinks.vue";
import NewQrcode from "./components/module/NewQrcode.vue";
import ModificaQrcode from './components/module/ModificaQrcode.vue';


const routes = [
    {
        path: "/login",
        name: "login",
        component: LoginApp,
        meta: {
            title: 'Login',
            //requiresAuth: false,
            sidebarVisible: false,
            from: '/login',
        },
    },
    {
        path: "/profilo",
        name: "profilo",
        component: Profilo,
        meta: {
            title: 'Profilo',
            requiresAuth: true,
            sidebarVisible: true,
            from: null, // This will be filled dynamically later

        },
    },
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: 'Home',
            requiresAuth: true,
            sidebarVisible: true
        },

    },
    {
        path: "/qrcode",
        name: "qrcode",
        component: Qrcode,
        meta: {
            title: 'QR Code',
            requiresAuth: true,
            sidebarVisible: true
        },
    },
    {
        path: "/links",
        name: "links",
        component: Links,
        meta: {
            title: 'Links',
            requiresAuth: true,
            sidebarVisible: true
        },
    },
    {
        path: "/new-links",
        name: "new-links",
        component: NewLinks,
        meta: {
            title: 'Nuovo Link',
            requiresAuth: true,
            sidebarVisible: true
        },
    },
    {
        path: "/new-qrcode",
        name: "new-qrcode",
        component: NewQrcode,
        meta: {
            title: 'Nuovo QR Code',
            requiresAuth: true,
            sidebarVisible: true
        },
    },
    {
        path: '/qrcode/:id/edit',
        name: 'ModificaQrcode',
        component: ModificaQrcode,
        props: true,
        meta: {
            title: 'Modifica QR Code',
            requiresAuth: true,
            sidebarVisible: true
        },
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('access_token');

    // se non sei autenticato e la rotta richiede autenticazione, reindirizza alla pagina di login
    if (!token && to.meta.requiresAuth) {
        next('/login');
    } else {
        const valid_token = await checkTokenValidityAndRenew();
        if (valid_token) {
            next();
        } else {
            next('/login');
        }
    }
});

// Questo metodo viene chiamato ogni volta che la rotta viene modificata
router.afterEach((to) => {
    // Aggiorniamo il titolo della pagina con quello definito nella proprietà meta.title della rotta
    document.title = to.meta.title || 'La mia applicazione';
});

export default router;