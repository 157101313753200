<template>
  <div class="content-header sticky-top">
    <h1 class="section--header">Crea un codice QR</h1>

    <a href="#" class="btn btn-primary">
      <svg viewBox="0 0 20 20" height="20" width="20">
        <path d="M4.167 9.167H7.5c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167V7.5c0 .917.75 1.667 1.667 1.667zm0-5H7.5V7.5H4.167V4.167zm0 13.333H7.5c.917 0 1.667-.75 1.667-1.667V12.5c0-.917-.75-1.667-1.667-1.667H4.167c-.917 0-1.667.75-1.667 1.667v3.333c0 .917.75 1.667 1.667 1.667zm0-5H7.5v3.333H4.167V12.5zm6.666-5V4.167c0-.917.75-1.667 1.667-1.667h3.333c.917 0 1.667.75 1.667 1.667V7.5c0 .917-.75 1.667-1.667 1.667H12.5c-.917 0-1.667-.75-1.667-1.667zm1.667 0h3.333V4.167H12.5V7.5zm5 9.583v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.184.417.417.417h.833a.413.413 0 00.417-.417zm-6.667-5v-.833c0-.233.184-.417.417-.417h.833c.234 0 .417.184.417.417v.833a.413.413 0 01-.417.417h-.833a.413.413 0 01-.417-.417zm2.917.417h-.833a.413.413 0 00-.417.417v.833c0 .233.183.417.417.417h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417zm-2.917 2.917v-.834c0-.233.184-.416.417-.416h.833c.234 0 .417.183.417.416v.834a.413.413 0 01-.417.416h-.833a.413.413 0 01-.417-.416zm2.084 2.083h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.183.417.417.417zm2.5-1.667h-.834a.413.413 0 01-.416-.416v-.834c0-.233.183-.416.416-.416h.834c.233 0 .416.183.416.416v.834a.413.413 0 01-.416.416zm0-5h-.834a.413.413 0 00-.416.417v.833c0 .234.183.417.416.417h.834a.413.413 0 00.416-.417v-.833a.413.413 0 00-.416-.417zm1.666 3.334h-.833a.413.413 0 01-.417-.417v-.833c0-.234.184-.417.417-.417h.833c.234 0 .417.183.417.417v.833a.413.413 0 01-.417.417z" clip-rule="evenodd">
        </path>
      </svg>
      Crea codice QR</a>
  </div>

  <div class="container-fluid ps-0 qrc-page-container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7">
        <div class="" style="align-self: center;width: 100%;max-width: 45rem;padding: 6.4rem 2.4rem;margin: auto;">
          <div class="header-content-top">
            <!-- Seleziona lo stato attuale: Sospeso o Pubblico -->
            <h1 class="orb-typography h2 no-margin header-title">Modifica Qr Code {{ $route.params.id }}
              <span :class="{'badge rounded-pill bg-danger': shortlink.post_status === 'draft', 'badge rounded-pill bg-success': shortlink.post_status !== 'draft'}">
                {{ shortlink.post_status === 'draft' ? 'Sospeso' : 'Pubblico' }}
              </span>
            </h1>
          </div>

          <div class="header-info created mb-5">
            <span class="created-info">
              <p class="orb-typography p no-margin">{{ shortlink.data }} <i>by {{ shortlink.autore }}</i></p>
            </span>
          </div>

          <form @submit.prevent="submitForm">
            <div class="mb-5">
              <label for="exampleFormControlInput1" class="form-label">Destination URL</label>
              <input type="text" id="qrcode_link" v-model="shortlink.qrcode_link" class="form-control" />

              <div class="body-longurl">
                <span class="orb-icon" title="redirect">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                    <title>redirect</title>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"></path>
                  </svg>
                </span>
                <span class="link-destination-url">
                    <template v-if="shortlink.qrcode_link_type === 'direct'">
                      <a
                          :href="`${shortlink.qrcode_link}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="redirect-anchor"
                          :title="shortlink.title"
                      >
                        {{ shortlink.qrcode_link }}
                      </a>
                    </template>
                    <template v-else>
                       <a
                           :href="`https://plink.io/${shortlink.qrcode_shortlink}`"
                           target="_blank"
                           rel="noopener noreferrer"
                           class="redirect-anchor"
                           :title="shortlink.title"
                       >
                        plink.io<span class="link--hash">{{ `/${shortlink.qrcode_shortlink}` }}</span>
                      </a>
                    </template>
                </span>
                <div class="disable-redirect">
                  <div tabindex="0" class="orb-tooltip top-center disable-redirect-tooltip">
                    <div class="anchor">
                      <span>
                        <span class="orb-icon redirect-lock">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <title>Icon</title>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-5">
              <label for="exampleFormControlInput1" class="form-label">Titolo <small>(Facoltativo)</small></label>
              <input type="text" id="title" v-model="shortlink.title" class="form-control" />
            </div>

            <div class="mb-5">
              <!-- Aggiungi un v-model per lo stato e due opzioni per Sospeso e Pubblico -->
              <label for="post_status" class="form-label">Stato</label>
              <div class="form-check form-switch custom-control-lg">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="post_status"
                    name="post_status"
                    v-model="shortlink.post_status"
                    true-value="publish"
                    false-value="draft"
                >
                <label class="form-check-label" for="post_status">
                  {{ shortlink.post_status === "draft" ? "Sospeso" : "Pubblico" }}
                  <p class="link-info--help-text">Cambia lo stato del QR code tra "Sospeso" e "Pubblico". Quando lo stato è "Sospeso", il QR code non sarà accessibile al pubblico, mentre quando è "Pubblico"
                    sarà visibile a tutti. Scegli lo stato che meglio si adatta alle tue esigenze e clicca sulla levetta dello switch per attivare la funzionalità corrispondente.</p>
                </label>
              </div>
            </div>


            <div id="response-message"></div>

            <button type="submit" class="btn btn-primary">Salva</button>
          </form>
        </div>
      </div>

      <div class="col-12 col-md-5 sticky-top">
        <div class="preview-container">
          <h4 class="orb-typography h4">Preview</h4>
          <div class="qr-preview-image">
            <img :src="`https://test.principiadv.online/wp-content/uploads/qrcode/${shortlink.svg_name}`" alt="QR Code">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery'; // importa jQuery qui

export default {
  name: 'ModificaQrcode',
  data() {
    return {
      shortlink: {},
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      fetch(`https://test.principiadv.online/wp-json/shortlink/v1/shortlink?id=${this.$route.params.id}`)
          .then((response) => response.json())
          .then((data) => {
            this.shortlink = data[0];
          });
    },
    async submitForm() {
      try {
        const response = await axios.post(
            `https://test.principiadv.online/wp-json/shortlink/v1/shortlink/${this.$route.params.id}`,
            this.shortlink
        );
        console.log(response.data);


        const box = document.createElement('div');
        box.classList.add('alert', 'alert-success', 'alert-dismissible', 'fade', 'show');
        box.setAttribute('role', 'alert');
        box.innerHTML = response.data.message + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';

        $('#response-message').empty().append(box);
        setTimeout(() => {
          box.remove();
        }, 4000);



      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.body-longurl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6em 1em;
  font-size: 0.9em;
}
.body-longurl svg {
  font-size: 1.2em;
  vertical-align: middle;
  margin-top: -4px;
}
.body-longurl .link-destination-url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0.75rem;
  font-size: 1em;
  max-width: calc(100vw/2.75);
}
.body-longurl .link-destination-url a {
  color: #273144;
}
.body-longurl .link-destination-url a .link--hash{
  font-weight: bold;
}
</style>