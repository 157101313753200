<template>
  <div class="layout--primary-section new-nav">
    <nav class="top-nav">
      <a id="contentMenu" href="#" class="content-menu d-none d-lg-flex"><i data-feather="menu"></i></a>
      <a id="mobileMenu" href="#" class="content-menu d-lg-none"><i data-feather="menu"></i></a>

      <div class="sidebar-search">
        <div class="search-body">
          <i data-feather="search"></i>
          <input type="text" class="form-control" placeholder="Search...">
        </div><!-- search-body -->
      </div>

      <div class="sidebar-footer">
        <div class="dropdown" v-if="state.user">
          <a href="#" class="dropdown-toggle d-flex" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false">

            <div class="avatar online">
              <span class="avatar-initial">{{ state.user.display_name.charAt(0) }}</span>
            </div>
            <div class="avatar-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6>{{ state.user.display_name }}</h6>
              </div>
              <span v-if="state.user.user_email.length" >{{ state.user.user_email }}</span>
            </div>

          </a>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
            <h6 class="dropdown-header">{{ state.user.display_name }}</h6>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" :to="'/profilo'">Profile</router-link>
            <a class="dropdown-item" @click="logout">Logout</a>
          </div>
        </div>
      </div>

    </nav>

  </div>
</template>

<script>
import { reactive } from 'vue';
import { removeToken, checkTokenValidityAndRenew, redirect, getUserData } from '../api';

export default {
  name: 'ToolBar',
  setup() {
    const state = reactive({
      loading: false,
      user: null,
    });

    async function getUser() {
      try {
        const userData = await getUserData();
        state.user = userData;
      } catch (error) {
        console.error(error);
      }
    }

    const logout = async() => {
      try {
        await checkTokenValidityAndRenew(); // controlla la validità del token prima di effettuare il logout
        removeToken();
        state.user = null;
        redirect('/login');
      } catch (e) {
        console.error(e);
      }
    };

    getUser();

    return {
      state,
      logout,
    };
  },
};
</script>